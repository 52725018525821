
@mixin fullBleed {
    width: 100vw;
    max-width: none;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
@mixin contentWidth {
    width: $contentWidth;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@mixin sectionTitle {
    //margin: .3em 10% 1em;
    margin: .3em 0 1em;
    text-align: center;
    font-size: 2.3em;
    font-weight: normal;
    text-transform: lowercase;
    color: $lightGrey;
    line-height: .65em;
    border-bottom: 1px solid #e7e9ee;
    span {
        //font-size: 1.1em;
        color: $black;
    }
    a {
        color: $lightGrey;
        &:hover {
            //text-decoration: none;
        }
    }
}
@mixin sectionStrapline {
    margin: -1.5em 10% 2em;
    text-align: center;
    font-size: .9em;
    font-style: italic;
    color: $grey;
    a {
        color: $grey;
    }
}

@mixin backgroundNoise {
    // http://www.patternify.com/
    //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVQYV2NMKL/ty4ADMIIkF3SqbsYmP+gkAayXGgfe8HOVAAAAAElFTkSuQmCC) repeat;
}

@mixin image {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin box_shadow {
    background-color: #f0f0f0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 2px rgba(0,0,0,0.08);
}

@mixin maciej_numbers {
    @include fullBleed;
    position: relative;
    background: #f0f0f0 url(https://lh3.googleusercontent.com/4ep3s6lWojp_fqypiNoy5KT-4IkrlB2q3tRrslRSvYp7Bf5biqLeTv6cX2g89uGkdvXGxiJ9czn1TvDusDU=w1000-no-tmp.jpg) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    .dark-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 15, 30, 0.8);
    }
    .section {
        position: relative;
        z-index: 2;
        margin: 0 10%;
        padding-top: 2em;
        h2 {
            @include sectionTitle;
            border: none;
            color: #aaa;
            span {
                color: #aaa;
            }
            .yellow {
                color: $highlightYellow;
            }
            .strikethrough {
                text-decoration: line-through;
            }
        }
        .strapline {
            @include sectionStrapline;
            margin-bottom: 0;
        }
    }
    .numbers {
        position: relative;
        z-index: 2;
        margin: 0 10%;
        padding: 3em 0 1em;
        display: flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        .number {
            width: 30%;
            margin-bottom: 2em;
            padding-top: 55px;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            background-position: top center;
            text-align: center;
            color: #eee;

            a {
                color: #eee;
            }
            span {
                color: $highlightYellow;
                font-size: 1.4em;
            }

            .more {
                margin-top: .5em;
                font-size: .7em;
                line-height: 1.4em;
                color: #aaa;

                a {
                    color: #ccc;
                }
            }

            &.time {
                background-image: url(../../public/img/icons/time.svg);
            }
            &.projects {
                background-image: url(../../public/img/icons/project.svg);
            }
            &.commits {
                background-image: url(../../public/img/icons/commit.svg);
            }
            &.contributions {
                background-image: url(../../public/img/icons/code.svg);
            }
            &.posts {
                background-image: url(../../public/img/icons/feather.svg);
            }
            &.location {
                background-image: url(../../public/img/icons/pin.svg);
            }
        }
    }
    .load-more {
        position: relative;
        z-index: 2;
        //margin-top: -1em;
        padding-bottom: 2em;
        text-align: center;
        a {
            background-color: $highlightYellow;
            color: $almostBlack;
            &:hover {
                background-color: $lightYellow;
                cursor: pointer;
            }
        }
    }
}

@mixin maciej_numbers_mobile {
    .section {
        h2 {
            margin-left: 0;
            margin-right: 0;
            font-size: 1.6em;
            line-height: 1.2em;
        }
        .strapline {
            margin: -.5em 0 2em;
            line-height: 1.3em;
        }
    }
    .numbers {
        display: block;
        padding: 0;
        .number {
            width: 100%;
            margin: 1em 0;
            padding-top: 0;
            background-image: none !important;
        }
    }
    .as-seen-on, .links {
        display: none;
    }
}

@mixin maciej_as_seen_on {
    .as-seen-on {
        position: relative;
        z-index: 2;
        margin-bottom: -.5em;
        text-align: center;
        color: rgba(255,255,255,.1);
        text-transform: uppercase;
        font-size: .7em;
        letter-spacing: 2px;
    }
    .links {
        position: relative;
        z-index: 2;
        display: flex;
        padding-bottom: 1.5em;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        .link {
            img {
                height: 30px;
                margin: 0 .5em;
                -moz-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
            }

            a {
                opacity: .8;

                &:hover {
                    opacity: 1;

                    img {
                        -moz-transform: scale(1.1);
                        -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }

            &.laurel img {
                height: 90px;
            }
            &.drupal img {
                margin-top: 4px;
            }
            &.github img {
                height: 25px;
                margin-bottom: 4px;
            }
            &.stackoverflow img {
                height: 55px;
                margin: 0 0 5px 0;
            }
            &.stackexchange img {
                height: 60px;
                margin: 0 0 22px 0;
            }
        }
    }
}
