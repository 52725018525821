@import '_variables';
@import '_mixins';

@media only screen and (min-width: 761px) and (max-width: 900px) {
    html {
        body {
            header {
                .site-name {
                    margin-right: 0;
                }
                .menu {
                    .item {
                        a {
                            padding: .8em .4em;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 761px) and (max-width: 1100px) {
    html {
        body {
            main {
                .content-wrapper {
                    .numbers-wrapper {
                        .section {
                            h2 {
                                line-height: 1.2em;
                            }
                        }

                        .as-seen-on, .links {
                            display: none;
                        }
                    }
                    article {
                    }
                }
            }
        }
    }
}
