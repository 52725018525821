$contentWidth: 900px;

$black: #000;
$almostBlack: #222;
$white: #fff;
$almostWhite: #ccc;
$link: #bf0000;
$darkLink: #8c0000;
$grey: #999;
$darkGrey: #555;
$lightGrey: #bbb;
$highlightYellow: #FFD625;
$lightYellow: #FFE69F;
$veryLightYellow: #FFF5D5;

$phpBlue: #8892BF;
$drupalBlue: #0678be;
$drupalDarkBlue: #05629b;
$symfonyGreen: #4d8400;
$silexViolet: #4353cd;
$ubuntuOrange: #e95420;
$nodejsGreen: #43853d;
