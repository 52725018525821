@import '_variables';
@import '_mixins';

@media only screen and (max-width: 760px) {
    html {
        body {
            font-size: 1.4em;

            header {
                display: block;
                padding: 0;

                .site-name {
                    margin-right: 0;
                    display: flex;
                    justify-content: space-between;
                    -webkit-justify-content: space-between;
                    align-items: center;
                    -webkit-align-items: center;

                    .item.name {
                        //font-weight: bold;
                        a {
                            display: block;
                            padding: .6em .8em;
                        }
                    }

                    .item.burger {
                        width: 24px;
                        height: 24px;
                        margin-right: .8em;
                        background: transparent url(../../public/img/icons/burger-white.svg) no-repeat center center;
                        background-size: 20px 20px;

                        &.menu-visible {
                            background-image: url(../../public/img/icons/burger-close.svg);
                            background-size: 16px 16px;
                        }
                    }
                }

                nav {
                    display: none;
                    font-size: .9em;

                    .menu {
                        display: block;
                        margin: 0 1em;

                        .item {
                            display: block;
                            border-top: 1px solid #f0f0f0;

                            a {
                                padding: .5em;
                            }
                        }
                    }

                    &.is-visible {
                        display: block;
                    }
                }
            }

            header.is-opaque,
            header.is-hovered {
                .item.burger {
                    background-image: url(../../public/img/icons/burger-black.svg);
                    background-size: 20px 20px;
                }
            }

            main {
                .content-wrapper {

                    h2.section-title {
                        margin: 0 0 .5em;
                        line-height: 1.2em;
                        font-size: 1.6em;
                    }

                    h3.subsection-title {
                        font-size: 1em;
                        letter-spacing: 3px;
                    }

                    .section-strapline {
                        margin: 0 0 2em;
                        line-height: 1.4em;
                    }

                    .children-wrapper {
                        .children {
                            .list {
                                display: block;

                                .child {
                                    width: 100%;
                                    margin-top: .5em;
                                }
                            }
                        }
                    }

                    .posts-featured {
                        .featured-title-wrapper {
                            .parent {
                                margin-top: 3em;
                            }
                            .title {
                                font-size: 1.8em;
                            }

                            .strapline {
                                margin: 1em 10% 0;
                                font-size: .9em;
                                max-height: 11.2em;
                                overflow: hidden;
                            }
                        }
                    }

                    .posts-featured-small {
                        padding: 0 1em;
                        .featured-title-wrapper {
                            .section-title {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .posts-wrapper {
                        margin-top: 2em;
                        margin-bottom: 2em;
                    }

                    .posts-grid-wrapper {
                        margin-top: .05em;
                        padding: 0 1em;

                        .posts {
                            .list {
                                display: block;

                                .post {
                                    width: 100%;
                                    padding-top: 5em;

                                    .text {
                                        .title {
                                            line-height: 1.2em;
                                            max-height: 2.4em;
                                            overflow: hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .calendar-wrapper {
                        padding: 0 1em;
                        .calendar-year {
                            a {
                                padding: .6em 1em;
                            }
                        }
                    }

                    .quote-wrapper {
                        padding: 2em 0;
                        font-size: .9em;
                        line-height: 1.4em;
                    }

                    article {
                        .flex {
                            display: block;
                        }

                        .w50 {
                            width: 48.7%;
                        }

                        .w33 {
                            width: 31.5%;
                        }
                        .aside.aside-left,
                        .aside.aside-right {
                            float: none;
                            width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    .load-more {
                        a {
                            padding: 0 .6em;
                            font-size: .8em;
                        }
                    }

                    // Home page.
                    .numbers-wrapper {
                        @include maciej_numbers_mobile;
                        .development-wrapper {
                            .posts {
                                display: block;
                                padding: .5em 0;
                                .post {
                                    padding: .5em 1em;
                                }
                            }
                        }
                    }

                    .section-travel, .section-sport {
                        padding: 2em 0;
                    }

                    .section-instagram {
                        min-height: calc(50vw + 50px + 1em);
                        .users {
                            flex-wrap: wrap;
                        }
                        .posts {
                            flex-wrap: wrap;
                            .post {
                                width: 25%;
                                height: 25vw;
                            }
                        }
                    }
                }

                .main-footer-wrapper {
                    padding: .5em 0;
                    font-size: .7em;

                    .main-footer {
                    }
                }
            }

            footer {
                .footer {
                    display: block;
                    .links {
                        h3 {
                            width: 4em;
                            min-width: 4em;
                        }
                    }
                    .other-links {
                        margin-left: 0;
                        padding-left: 6em;
                        border-left: 0 none;
                    }
                }
            }

            h2 {
                font-size: 1.3em;
            }

            blockquote {
                margin-left: 20px;
                margin-right: 0;
            }

        }

        body.template-list {
            main {
                .content-wrapper {
                    .posts-list-wrapper {
                        .posts {
                            .list {
                                .post {
                                    display: block;
                                    margin-top: 1em;

                                    .text {
                                        .strapline {
                                            height: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        body.hero {
            .image.hero {
                .hi {
                    bottom: 12%;
                    font-size: 1.5em;
                }
            }

            main {
                .page-title-wrapper {
                    .page-title {
                        h1 {
                            font-size: 2em;
                        }
                    }

                    .strapline {
                        margin-left: 5%;
                        margin-right: 5%;
                        font-size: .9em;
                    }
                }
            }
        }

        body.tag-maciej {
            main {
                article {
                    .numbers-wrapper {
                        @include maciej_numbers_mobile;

                        .numbers {
                            padding-bottom: 2em;
                        }
                        .also-wrapper {
                            .also-links {
                                flex-wrap: wrap;
                                .link {
                                    width: 50%;
                                }
                            }
                        }
                    }

                    .experience, .clients {
                        padding: 2em 0;

                        .section {
                            h2 {
                                font-size: 1.6em;
                                line-height: 1.2em;
                            }

                            .strapline {
                                margin: -.5em 0 2em;
                                line-height: 1.2em;
                            }
                        }
                    }

                    .experience {
                        .employer-list {
                            .employer {
                                display: block;

                                .logo {
                                    margin-right: 0;
                                }

                                & > * {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .clients {
                        .client-logos {
                            .client-logo {
                                width: 25%;
                                margin: 0;

                                img {
                                    width: 80%;
                                    height: 80%;
                                }
                            }
                        }
                    }
                }

                .recommendations-wrapper {
                    .recommendations {
                        .recommendation {
                            display: block;

                            .photo {
                                float: left;
                            }

                            .details {
                                .author {
                                    display: block;
                                    font-size: .7em;
                                    line-height: 1.4em;
                                }
                            }
                        }
                        .slick-prev, .slick-next {
                            display: none !important;
                        }
                    }
                }

                .content-wrapper {
                    .posts-wrapper {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding: 2em 0;

                        .posts {
                            .list {
                                display: block;

                                .post {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
