@import '_variables';
@import '_mixins';

* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

p, pre {
    margin: 1em 0;
}

a {
    color: $link;
    text-decoration: none;
    //border-bottom: 0.0625rem solid #dcdcdc;
    &:hover {
        text-decoration: underline;
        //border-bottom: 0.0625rem solid $link;
    }
    &::selection {
        background-color: #ffe500;
        color: $almostBlack;
    }
    code {
        color: $link;
    }
    &.anchor {
        color: $black;
        display: flex;
        align-items: center;
        &:hover {
            text-decoration: none;
            &:after {
                content: '¶';
                margin-left: .1em;
                font-family: sans-serif;
                font-size: .9em;
                color: $link;
            }
        }
    }
}

code {
    color: #008000;
}

pre {
    background-color: #1e2125;
    line-height: 1.4em;

    //white-space: pre-wrap;
    //white-space: -moz-pre-wrap;
    //white-space: -pre-wrap;
    //white-space: -o-pre-wrap;
    //word-wrap: break-word;
    overflow-x: auto;

    padding: .7em .8em .8em;
    font-size: .85em;
    code {
        color: $almostWhite;
    }
}

blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    font-style: italic;
}

.width100 {
    width: 100%;
}
.small {
    font-size: .75em;
    line-height: 1.5em;
}
.grey {
    color: #999;
}
.strikethrough {
    text-decoration: line-through;
}
body {
    margin: 0;
    padding: 0;
    font-size: 16px;

    overflow-x: hidden;
    font-size: 1.5em;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;

    .width-full-bleed {
        @include fullBleed;
    }
    .width-content {
        @include contentWidth;
        width: $contentWidth !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 50;
        display: flex;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        padding: 0 1em;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        /* Force Hardware Acceleration */
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform;
        -webkit-transition: -webkit-transform .5s;
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        ul {
            margin: 0;
            padding: 0;
        }

        .site-name {
            margin-right: 2em;
            a {
                span {
                    opacity: .4;
                }
            }
        }

        .site-name, .menu {
            .item {
                display: inline-block;
                a {
                    display: block;
                    padding: .8em .6em;
                    border: none;
                    color: #eee;
                }
            }
        }

        nav {
            flex-grow: 1;
            .menu {
                display: flex;
                .item.blog {
                    margin-left: auto;
                }
            }
        }

        &.is-expanded,
        &.is-opaque {
            background-color: rgba(255, 255, 255, 0.95);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &.is-hovered {
            background-color: #fcfcfc;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &.is-expanded,
        &.is-opaque,
        &.is-hovered {
            .item {
                a {
                    color: $almostBlack;
                    &:hover {
                        color: $link;
                        text-decoration: none;
                    }

                }
            }
        }

        &.is-hidden {
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
        }
    }

    main {
        flex: 1 0 auto;
    }

    .immersive-image-wrapper {
        z-index: -10;
        overflow: hidden;
        background-color: $almostBlack;
        width: 100%;
        height: 100vh;
        position: relative;
    }
    .hero-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
    }
    .image.immersive {
        width: 110%;
        height: 110%;
        margin-top: -5%;
        margin-left: -5%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;

        &.fixed {
            background-attachment: fixed;
        }
        &.small {
            -webkit-filter: blur(30px);
            -moz-filter: blur(30px);
            -o-filter: blur(30px);
            -ms-filter: blur(30px);
            filter: url(#blur);
            filter: blur(30px);
            filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
            -webkit-transition: 2s -webkit-filter linear;
            -o-transition: 2s -o-filter linear;
        }
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        width: 100%;
        height: 100vh;
        &.solid-overlay {
            background-color: $black;
            opacity: 0.4;
            filter: alpha(opacity=80);
        }
        &.gradient-overlay {
            background: linear-gradient(rgba(0,130,170,0),$almostBlack);
        }
    }

    .grow {
        transition: all .2s ease-in-out;
        &:hover {
            transform: scale(1.05);
        }
    }

    iframe {
        //background: $lightGrey url(/img/colorbox/spinner.gif) no-repeat center center;
    }

    .loading {
        height: 36px;
        margin: 3em 0;
        font-size: .8em;
        background: transparent url(../../public/img/colorbox/spinner.gif) no-repeat center center;
        -webkit-background-size: 30px 30px;
        background-size: 30px 30px;
    }

    .content-wrapper {

        h2.section-title {
            @include sectionTitle;
            margin-top: 1.5em;
            //border-bottom: 1px solid #e7e9ee;
        }
        .section-strapline {
            @include sectionStrapline;
        }
        h3.subsection-title {
            margin: 1em auto 1em;
            padding: 0 1em;
            text-align: center;
            font-weight: normal;
            color: $grey;
            text-transform: uppercase;
            letter-spacing: 4px;
            a {
                color: $link;
            }
        }

        .posts-featured {
            @include fullBleed;
            height: 90vh;
            .immersive-image-wrapper {
                height: 90vh;
                .immersive {
                    //height: 100vh;
                    height: 120%;
                }
            }
            .featured-title-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
                display: flex;
                flex-direction: column;
                -webkit-flex-direction: column;
                justify-content: center;
                -webkit-justify-content: center;
                align-items: center;
                -webkit-align-items: center;
                text-align: center;
                & > * {
                    text-align: center;
                }
                a {
                    color: $white;
                    &:hover {
                        color: $white;
                    }
                }
                .section-title {
                    display: inline-block;
                    margin: 2em auto 0;
                    padding: .3em .4em .2em;
                    background-color: $white;
                    color: $black;
                    font-size: .6em;
                    line-height: 1em;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    a {
                        color: #000;
                    }
                }
                .parent {
                    margin-top: 4em;
                    text-transform: uppercase;
                    font-size: .6em;
                    letter-spacing: 2px;
                    color: $white;
                }
                .title {
                    margin: 0em 10%;
                    font-size: 2em;
                    font-weight: normal;
                    line-height: 1.2em;
                }
                .strapline {
                    margin: 2em 20% 0;
                    text-align: center;
                    //font-size: 1.2em;
                    line-height: 1.4em;
                    font-style: italic;
                    color: $white;
                    //background-color: $black;
                }
                .author {
                    margin-top: 3em;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: .5em;
                    letter-spacing: 2px;
                    color: $almostWhite;
                    a {
                        color: $almostWhite;
                    }
                }
            }
        }
        .posts-featured-small {
            padding: 0 2em;
            height: 50vh;
            .immersive-image-wrapper {
                height: 50vh;
            }
            .featured-title-wrapper {
                .section-title {
                    margin: -2em auto 3em;
                }
                .parent {
                    margin-top: 0;
                }
            }
        }

        .posts-wrapper {
            margin: 3em auto 1em;
        }
        .posts-list-wrapper {
            @include contentWidth;
            .posts {
                .load-more {
                    margin: 3em 0;
                }
            }
        }
        .posts-grid-wrapper {
            @include fullBleed;
            margin-top: .05em;
            padding: 0 2em;
            .posts {
                .list {
                    display: flex;
                    justify-content: space-between;
                    -webkit-justify-content: space-between;
                    flex-wrap: wrap;
                    -webkit-flex-wrap: wrap;
                    .post {
                        width: calc(33.33% - 2em / 3);
                        margin: 0 0 1em;
                        padding-top: 25%;
                        position: relative;
                        .image {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-attachment: scroll;
                            background-position: center center;
                            background-color: $almostBlack;
                        }
                        .text {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            z-index: 10;
                            padding: .5em 1em;
                            color: $white;
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .calendar-wrapper {
            @include fullBleed;
            //margin-top: -1em;
            margin-bottom: 1em;
            padding: 0 2em;
            display: flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            .calendar-year {
                width: 100%;
                a {
                    display: block;
                    padding: 1em;
                    text-align: center;
                    background: transparent url(https://lh3.googleusercontent.com/EwZIEKMEqYWFfhLkU056a6_FKP3gji8OWyQGCq4KS-j0eOLOqbfHEPFez2WODm-hYIHQ9PHtFXqHh7F1rKI=w500-no-tmp.jpg) no-repeat center center;
                    background-size: cover;
                    -webkit-background-size: cover;
                    -moz-background-size: cover;
                    -o-background-size: cover;
                    color: $white;
                }
                &:nth-child(2) {
                    margin-left: 1em;
                }
            }
        }

        .load-more {
            text-align: center;
            font-size: .8em;
            a {
                display: inline-block;
                padding: 0em 1em;
                background-color: $link;
                color: white;
                -moz-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                &:hover {
                    background-color: $darkLink;
                }
            }
        }
    }

    footer {
        flex-shrink: 0;
        margin: 0;
        padding: 2em 0 .5em;
        background-color: $almostBlack;
        h3, ul {
            margin: 0;
            padding: 0;
        }

        .footer {
            @include contentWidth;
            font-size: .65em;
            line-height: 1.5em;
            color: $grey;
            display: flex;
            .links {
                display: flex;
                margin: 1em 0;
                h3 {
                    width: 5em;
                    min-width: 5em;
                    color: $almostWhite;
                    text-align: right;
                    margin-right: 2em;
                    font-weight: normal;
                    font-size: 1em;
                    a {
                        color: $almostWhite;
                        &:hover {
                            color: $white;
                            text-decoration: none;
                        }
                    }
                }
                ul {
                    li {
                        display: inline-block;
                        margin-right: .5em;
                        a {
                            color: $grey;
                            &.dark {
                                //color: #666;
                            }
                            &:hover {
                                color: $white;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .other-links {
                margin: 1em 0;
                margin-left: 2em;
                padding-left: 2em;
                border-left: 1px solid #444;
                a {
                    color: $grey;
                }
            }
        }
        .made-with-love {
            display: block;
            margin-top: 3em;
            text-align: center;
            font-size: .6em;
            color: #444;
        }
    }
}
